import React, { Component } from "react";
import { connect } from "react-redux";
import { IAppState, IAppStateAuth } from "../interfaces/IAppState";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import Layout from "../components/Layout";
import ReportScreen from "../screens/ReportScreen";
import LoginScreen from "../screens/LoginScreen";
import SearchScreen from "../screens/SearchScreen";

type Props = {
	auth: IAppStateAuth;
}

class NavigationWrapper extends Component<Props> {

	authenticatedRouter = () => (
		<Layout>
			<Switch>
				<Route path="/search">
					<SearchScreen/>
				</Route>
				<Route path="/">
					<ReportScreen/>
				</Route>
			</Switch>
		</Layout>
	);

	loginRouter = () => (
		<Layout fullHeight={true}>
			<LoginScreen/>
		</Layout>
	);

	render() {
		const { auth } = this.props;
		return (
			<Router>
				{auth.token ? this.authenticatedRouter() : this.loginRouter()}
			</Router>
		);
	}

}

const mapStateToProps = (state: IAppState) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(NavigationWrapper);
