import React, { Component } from "react";
import { connect } from "react-redux";
import { IAppState, IAppStateAuthProfile } from "../../interfaces/IAppState";

type Props = {
	profile: IAppStateAuthProfile|null;
}

class Navbar extends Component<Props> {

	render() {
		const { profile } = this.props;
		return (
			<nav className={"bg-blue p-6 shadow"}>
				<div className={"mx-auto container flex items-center justify-between flex-wrap"}>
					<div className={"flex items-center justify-start"}>
						<a
							className="flex items-center flex-shrink-0 text-white"
							href={"/"}
						>
							<img src={"./img/logo-text-white.png"} width={125}/>
							<span className={"text-white ml-5 font-bold"}>
								Safety Center
							</span>
						</a>
						<div className={"border-l-2 border-white ml-10 pl-10"}>
							<a
								className={"text-white font-medium hover:text-gray-400 mr-8"}
								href={"/"}
							>
								Reports
							</a>
							<a
								className={"text-white font-medium hover:text-gray-400"}
								href={"/search"}
							>
								Search
							</a>
						</div>
					</div>
					{profile ? (
						<a
							className={"flex flex-row items-center bg-white py-2 px-3 rounded-lg shadow"}
							href={"#"}
							onClick={() => {
								// localStorage.clear();
								// window.location.replace("/");
							}}
						>
							<img
								src={profile.imageUrl}
								className={"inline object-cover w-6 h-6 mr-2 rounded-full"}
							/>
							<span className={"font-bold"}>
								{profile?.name}
							</span>
						</a>
					) : undefined}
				</div>
			</nav>
		);
	}

}

const mapStateToProps = (state: IAppState) => ({
	profile: state.auth.profile,
});

export default connect(mapStateToProps)(Navbar);
