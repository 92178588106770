import { AnyAction } from "redux";

const initialState = {
	profile: null,
	token: null,
};

export default function(state = initialState, action: AnyAction) {
	switch (action.type) {
		case "LOGIN": {
			return action.payload;
		}
		default: return state;
	}
}
