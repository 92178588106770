import React, { PureComponent } from "react";
import { MediaType, Post } from "../../api/NetworkManager";
import ReactPlayer from "react-player";
import GetCreatedDate from "../../utils/GetCreatedDate";

type Props = {
	post: Post;
}

class PostItem extends PureComponent<Props> {

	render() {
		const { post } = this.props;
		console.log(post);
		return (
			<div className={"bg-white p-5 rounded-md shadow-sm"}>
				{post.profile && (
					<div className={"flex items-center justify-start mb-5"}>
						<img
							src={post.profile?.avatar?.uri}
							style={{
								width: 50,
								height: 50,
								borderRadius: 25,
							}}
						/>
						<div className={"flex flex-col ml-3"}>
							<span className={"font-medium"}>
								{post.profile.name}
							</span>
							<span className={"text-gray-500"}>
									@{post.profile.username}
							</span>
						</div>
					</div>
				)}
				<div className={"mb-5"}>
					{post.content}
				</div>
				<div>
					{post.media?.length ? (
						<React.Fragment>
							<div className={"w-full rounded-lg flex"}>
								{post.media[0].type === MediaType.Image ? (
									<img
										src={post.media[0].uri}
										className={"w-full"}
									/>
								) : (
									<ReactPlayer
										url={post.media[0].uri}
										className={"block"}
										loop={true}
										controls={true}

									/>
								)}
							</div>
							<a
								className={"text-blue mt-3 flex items-center align-center w-100"}
								href={post.media[0].uri}
								download
							>
								Download
							</a>
						</React.Fragment>
					) : undefined}
				</div>
				<div className={"flex flex-row justify-between w-100 mt-5"}>
					{GetCreatedDate(post)}
					{post.geocode && (
						<div>
							{post.geocode.city}, {post.geocode.region}
						</div>
					)}
				</div>
			</div>
		);
	}

}

export default PostItem;
