import React, { Component } from "react";
import clsx from "clsx";
import { Report } from "../../api/NetworkManager";
import {
	AlertTriangle, Image, Clock, Trash, Check,
} from "react-feather";
import moment from "moment";

type Props = {
	report: Partial<Report>;
}

type State = {
	hideImage: boolean;
}

class ReportedItem extends Component<Props, State> {

	private VERTICAL_SPACING = 5;

	constructor(props: Props) {
		super(props);
		this.state = {
			hideImage: true,
		};
	}

	getReportType = () => {
		const { report } = this.props;
		if (report.post) {
			return "POST";
		}
		if (report.comment) {
			return "COMMENT";
		}
		if (report.story) {
			return "STORY";
		}
	};

	render() {
		const { report } = this.props;
		const { hideImage } = this.state;
		const reportType = this.getReportType();
		const hasImage = reportType === "POST" && report.post?.media?.length && false;
		const post = report.post || report.comment?.post;
		return (
			<div className={clsx("bg-white p-5 rounded-md shadow-sm", hasImage && "row-span-2")}>
				<div className={"flex flex-row justify-between"}>
					<div className={"flex flex-row items-center"}>
						<img
							src={report.profile?.avatar?.uri || "/img/user.jpg"}
							className={"inline object-cover w-8 h-8 mr-2 rounded-full"}
						/>
						<div className={"flex flex-col items-start justify-center ml-1"}>
							<span className={"text-xs"}>Reported by:</span>
							<span className={"text-sm font-bold"}>{report.profile?.username || "Anonymous User"}</span>
						</div>
					</div>
					<div className={"flex items-center"}>
						<Clock size={13}/>
						<span className={"text-sm ml-2"}>{moment(report.created).fromNow()}</span>
					</div>
				</div>
				<div className={`my-${this.VERTICAL_SPACING} flex flex-row justify-between border-t-2 border-b-2 py-5`}>
					<div className={
						"py-1 px-2 bg-yellow-500 text-white font-bold " +
						"text-sm rounded-md inline-flex items-center"
					}>
						<AlertTriangle size={13} className={"mr-1"} />
						{report.reason}
					</div>
					<div className={"flex flex-row justify-between"}>
						<a
							href={"#"}
							className={
								"py-1 px-2 bg-green-400 text-white text-sm " +
								"rounded-md inline-flex items-center mr-2 shadow hover:shadow-none"
							}
						>
							<Check size={12} className={"mr-1"} />
							Okay
						</a>
						<a
							href={"#"}
							className={
								"py-1 px-2 bg-red-400 text-white text-sm " +
								"rounded-md inline-flex items-center shadow hover:shadow-none"
							}
						>
							<Trash size={12} className={"mr-1"} />
							Delete
						</a>
					</div>
				</div>
				<div className={`mb-${this.VERTICAL_SPACING}`}>
					<span className={"text-sm font-medium"}>Report reason:&nbsp;</span>
					<blockquote className={"text-sm bg-gray-100 p-2 rounded mt-1"}>{report.content}</blockquote>
				</div>
				{post && (
					<div>
						<div className={`mb-${this.VERTICAL_SPACING} flex flex-row justify-between`}>
							<div className={"w-1/2 pr-2"}>
								<span className={"text-sm font-medium"}>Post author:&nbsp;</span>
								<blockquote className={clsx(
									"text-sm bg-gray-100 p-2 rounded mt-1 font-bold h-12",
									reportType === "POST" && "bg-red-100",
								)}>
									<img
										src={post.profile?.avatar?.uri || "/img/user.jpg"}
										className={"inline object-cover w-8 h-8 mr-2 rounded-full"}
									/>
									{post?.profile?.username || "Anonymous"}
								</blockquote>
							</div>
							<div className={"w-1/2 pl-2"}>
								<span className={"text-sm font-medium"}>Posted on:&nbsp;</span>
								<blockquote className={clsx(
									"text-sm bg-gray-100 p-2 rounded mt-1 font-bold flex items-center h-12",
									reportType === "POST" && "bg-red-100",
								)}>
									<Clock size={13}/>
									<span className={"text-sm ml-2"}>{moment(post.created).fromNow()}</span>
								</blockquote>
							</div>
						</div>
						<div className={`mb-${this.VERTICAL_SPACING}`}>
							<span className={"text-sm font-medium"}>Post content:&nbsp;</span>
							<blockquote className={clsx(
								"text-sm bg-gray-100 p-2 rounded mt-1", reportType === "POST" && "bg-red-100",
							)}>
								{post?.content}
							</blockquote>
						</div>
						{post?.media?.length ? (
							<a
								onClick={() => {
									this.setState({
										...this.state,
										hideImage: !hideImage,
									});
								}}
								className={
									"border-2 border-gray-100 shadow rounded overflow-hidden " +
									"relative flex items-center justify-center mt-5"
								}
							>
								{post?.media[0].uri.endsWith(".mp4") ? (
									<video controls>
										<source src={post?.media[0].uri || ""} type={"video/mp4"}/>
									</video>
								) : (
									<React.Fragment>
										<img
											src={post?.media[0].uri || ""}
											style={hideImage ? {
												filter: "blur(20px)",
											} : undefined}
										/>
										{hideImage && (
											<div
												className={
													"absolute text-white flex " +
											"flex-row justify-center items-center font-medium"
												}
											>
												<Image size={15} className={"mr-2"}/>
										Show Image
											</div>
										)}
									</React.Fragment>
								)}
							</a>
						) : undefined}
					</div>
				)}
				{reportType === "COMMENT" && (
					<div>
						<div className={`mb-${this.VERTICAL_SPACING} flex flex-row justify-between`}>
							<div className={"w-1/2 pr-2"}>
								<span className={"text-sm font-medium"}>Comment author:&nbsp;</span>
								<blockquote className={clsx(
									"text-sm bg-gray-100 p-2 rounded mt-1 font-bold",
									reportType === "COMMENT" && "bg-red-100",
								)}>
									<img
										src={report.comment?.profile?.avatar?.uri || "/img/user.jpg"}
										className={"inline object-cover w-8 h-8 mr-2 rounded-full"}
									/>
									{report.comment?.profile?.username || "Anonymous"}
								</blockquote>
							</div>
							<div className={"w-1/2 pl-2"}>
								<span className={"text-sm font-medium"}>Commented on:&nbsp;</span>
								<blockquote className={clsx(
									"text-sm bg-gray-100 p-2 rounded mt-1 font-bold flex items-center h-12",
									reportType === "COMMENT" && "bg-red-100",
								)}>
									<Clock size={13}/>
									<span className={"text-sm ml-2"}>{moment(report.comment?.created).fromNow()}</span>
								</blockquote>
							</div>
						</div>
						<div className={`mb-${this.VERTICAL_SPACING}`}>
							<span className={"text-sm font-medium"}>Comment content:&nbsp;</span>
							<blockquote className={clsx(
								"text-sm bg-gray-100 p-2 rounded mt-1",
								reportType === "COMMENT" && "bg-red-100",
							)}>
								{report.comment?.content}
							</blockquote>
						</div>
						{report.comment?.replyTo ? (
							<div>
								<div className={`my-${this.VERTICAL_SPACING} mt-6 border-t-2 pt-6`}>
							The reported comment was a reply to:
								</div>
								<div className={`mb-${this.VERTICAL_SPACING} flex flex-row justify-between`}>
									<div className={"w-1/2 pr-2"}>
										<span className={"text-sm font-medium"}>Comment author:&nbsp;</span>
										<blockquote className={clsx(
											"text-sm bg-gray-100 p-2 rounded mt-1 font-bold",
										)}>
											<img
												src={report.comment?.replyTo?.profile?.avatar?.uri || "/img/user.jpg"}
												className={"inline object-cover w-8 h-8 mr-2 rounded-full"}
											/>
											{report.comment?.replyTo?.profile?.username || "Anonymous"}
										</blockquote>
									</div>
									<div className={"w-1/2 pl-2"}>
										<span className={"text-sm font-medium"}>Commented on:&nbsp;</span>
										<blockquote className={clsx(
											"text-sm bg-gray-100 p-2 rounded mt-1 font-bold flex items-center h-12",
										)}>
											<Clock size={13}/>
											<span className={"text-sm ml-2"}>
												{moment(report.comment?.replyTo?.created).fromNow()}
											</span>
										</blockquote>
									</div>
								</div>
								<div className={`mb-${this.VERTICAL_SPACING}`}>
									<span className={"text-sm font-medium"}>Comment content:&nbsp;</span>
									<blockquote className={clsx(
										"text-sm bg-gray-100 p-2 rounded mt-1",
									)}>
										{report.comment?.replyTo?.content}
									</blockquote>
								</div>
							</div>
						) : undefined}
					</div>
				)}
			</div>
		);
	}

}

export default ReportedItem;
