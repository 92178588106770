import React, { Component } from "react";
import clsx from "clsx";
import Navbar from "./navbar/Navbar";

type Props = {
	children?: JSX.Element;
	fullHeight?: boolean;
}

class Layout extends Component<Props, any> {

	render() {
		const { children, fullHeight } = this.props;
		return (
			<div className={clsx(fullHeight && "h-screen overflow-hidden")}>
				<Navbar/>
				<div className={clsx("container mx-auto", fullHeight && "h-full")}>
					{children}
				</div>
			</div>
		);
	}

}

export default Layout;
