/* eslint-disable */
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Block = {
  __typename?: 'Block';
  _id: Scalars['String'];
  targetProfile?: Maybe<Profile>;
  created: Scalars['DateTime'];
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['String'];
  post: Post;
  profile?: Maybe<Profile>;
  score: Scalars['Float'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  content: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  replies?: Maybe<Array<Comment>>;
  replyTo?: Maybe<Comment>;
  replyCount?: Maybe<Scalars['Int']>;
  isUserAuthor?: Maybe<Scalars['Boolean']>;
};

export enum ContentAction {
  None = 'NONE',
  ContentWarn = 'CONTENT_WARN',
  ContentHide = 'CONTENT_HIDE',
  ContentRemove = 'CONTENT_REMOVE',
  UserWarn = 'USER_WARN',
  UserShadowBan = 'USER_SHADOW_BAN',
  UserBan = 'USER_BAN'
}

export type CursorInput = {
  afterCursor?: Maybe<Scalars['String']>;
  beforeCursor?: Maybe<Scalars['String']>;
};

export type CursorObject = {
  __typename?: 'CursorObject';
  afterCursor?: Maybe<Scalars['String']>;
  beforeCursor?: Maybe<Scalars['String']>;
};


export type Follower = {
  __typename?: 'Follower';
  _id: Scalars['String'];
  created: Scalars['DateTime'];
};

export type GeocodeInput = {
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GeocodeObject = {
  __typename?: 'GeocodeObject';
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetReportsResponse = {
  __typename?: 'GetReportsResponse';
  cursor?: Maybe<CursorObject>;
  data: Array<Report>;
};

export type Hashtag = {
  __typename?: 'Hashtag';
  _id: Scalars['String'];
  text: Scalars['String'];
  postIds: Array<Scalars['String']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LocationObject = {
  __typename?: 'LocationObject';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Media = {
  __typename?: 'Media';
  created: Scalars['DateTime'];
  type: MediaType;
  uri: Scalars['String'];
  /** A media preview in Base64 format. */
  preview?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
  height: Scalars['Float'];
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Mutation = {
  __typename?: 'Mutation';
  resolveReport: Scalars['Boolean'];
};


export type MutationResolveReportArgs = {
  input: ResolveReportInput;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  profile?: Maybe<Profile>;
  targetProfile?: Maybe<Profile>;
  type: NotificationType;
  post?: Maybe<Post>;
  comment?: Maybe<Comment>;
  viewed: Scalars['Boolean'];
};

export enum NotificationType {
  NewComment = 'NEW_COMMENT',
  PostUpvotes = 'POST_UPVOTES',
  PostIsTrending = 'POST_IS_TRENDING',
  NewFollower = 'NEW_FOLLOWER',
  MentionPost = 'MENTION_POST',
  MentionComment = 'MENTION_COMMENT',
  ReplyComment = 'REPLY_COMMENT',
  PollFinal = 'POLL_FINAL',
  FollowingNewPost = 'FOLLOWING_NEW_POST',
  Repost = 'REPOST',
  RepostComment = 'REPOST_COMMENT'
}

export type PollInput = {
  choices: Array<Scalars['String']>;
  nearbyOnly: Scalars['Boolean'];
  expires: Scalars['String'];
};

export type PollObject = {
  __typename?: 'PollObject';
  choices: Array<Scalars['String']>;
  nearbyOnly: Scalars['Boolean'];
  expires: Scalars['String'];
};

export type PollVote = {
  __typename?: 'PollVote';
  _id: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  choiceIndex: Scalars['Float'];
};

/** Represents a post made by a user. */
export type Post = {
  __typename?: 'Post';
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  score: Scalars['Float'];
  lang?: Maybe<Scalars['String']>;
  hashtags: Array<Scalars['String']>;
  repost?: Maybe<Post>;
  repostCount?: Maybe<Scalars['Float']>;
  content: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  media?: Maybe<Array<Media>>;
  geocode?: Maybe<GeocodeObject>;
  poll?: Maybe<PollObject>;
  pollVotes?: Maybe<Array<Scalars['Float']>>;
  isUserNearby?: Maybe<Scalars['Boolean']>;
  trendingReason?: Maybe<TrendingReason>;
  trendingHashtag?: Maybe<Scalars['String']>;
  commentCount?: Maybe<Scalars['Int']>;
  isUserAuthor?: Maybe<Scalars['Boolean']>;
  isFavorited?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  getReports: GetReportsResponse;
  searchPosts: SearchPostsResponse;
  status: Scalars['Boolean'];
};


export type QueryGetReportsArgs = {
  cursor?: Maybe<CursorInput>;
};


export type QuerySearchPostsArgs = {
  cursor?: Maybe<CursorInput>;
  input: SearchPostsInput;
};

export type Report = {
  __typename?: 'Report';
  _id: Scalars['String'];
  created: Scalars['DateTime'];
  profile?: Maybe<Profile>;
  user?: Maybe<User>;
  post?: Maybe<Post>;
  story?: Maybe<Story>;
  comment?: Maybe<Comment>;
  reason: Scalars['String'];
  content: Scalars['String'];
  action?: Maybe<ReportAction>;
  contentAction?: Maybe<ContentAction>;
  actionBy?: Maybe<Scalars['String']>;
};

export enum ReportAction {
  Ignore = 'IGNORE',
  Resolve = 'RESOLVE',
  Elevate = 'ELEVATE'
}

export type ResolveReportInput = {
  reportId: Scalars['String'];
  reportAction?: Maybe<ReportAction>;
  contentAction?: Maybe<ContentAction>;
};

export type SearchPostsInput = {
  query: Scalars['String'];
  hasMedia?: Maybe<Scalars['Boolean']>;
};

export type SearchPostsResponse = {
  __typename?: 'SearchPostsResponse';
  cursor?: Maybe<CursorObject>;
  data: Array<Post>;
};

export type Story = {
  __typename?: 'Story';
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  media: Media;
  geocode: GeocodeObject;
  textContent?: Maybe<Scalars['String']>;
  textPosition?: Maybe<Scalars['Float']>;
  isUserAuthor?: Maybe<Scalars['Boolean']>;
  viewCount: Scalars['Float'];
  viewed?: Maybe<Scalars['Boolean']>;
};

export enum TrendingReason {
  Nearby = 'NEARBY',
  Hashtag = 'HASHTAG'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  authenticatedProfiles?: Maybe<Array<Profile>>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  suspended: Scalars['Boolean'];
  score?: Maybe<Scalars['Float']>;
};

export type Vote = {
  __typename?: 'Vote';
  _id: Scalars['String'];
  direction: VoteDirection;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
};

export enum VoteDirection {
  Up = 'UP',
  Down = 'DOWN'
}

export type Profile = {
  __typename?: 'profile';
  _id: Scalars['String'];
  user: Array<User>;
  authenticatedUsers: Array<User>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  version: Scalars['Float'];
  isVerified?: Maybe<Scalars['Boolean']>;
  dob?: Maybe<Scalars['DateTime']>;
  avatar?: Maybe<Media>;
  instagram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  snapchat?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  tel: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  username: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  suspended: Scalars['Boolean'];
  warnings: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Float']>;
  followingCount?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  postCount?: Maybe<Scalars['Float']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isFollowing?: Maybe<Scalars['Boolean']>;
};

export type GetReportsQueryVariables = Exact<{
  cursor?: Maybe<CursorInput>;
}>;


export type GetReportsQuery = (
  { __typename?: 'Query' }
  & { getReports: (
    { __typename?: 'GetReportsResponse' }
    & { cursor?: Maybe<(
      { __typename?: 'CursorObject' }
      & Pick<CursorObject, 'beforeCursor' | 'afterCursor'>
    )>, data: Array<(
      { __typename?: 'Report' }
      & Pick<Report, '_id' | 'created' | 'reason' | 'content' | 'action'>
      & { post?: Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, '_id' | 'score' | 'content' | 'created'>
        & { profile?: Maybe<(
          { __typename?: 'profile' }
          & Pick<Profile, '_id' | 'username'>
          & { avatar?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'uri'>
          )> }
        )>, media?: Maybe<Array<(
          { __typename?: 'Media' }
          & Pick<Media, 'uri' | 'width' | 'height'>
        )>> }
      )>, story?: Maybe<(
        { __typename?: 'Story' }
        & Pick<Story, '_id' | 'textContent' | 'created'>
        & { media: (
          { __typename?: 'Media' }
          & Pick<Media, 'uri'>
        ), profile?: Maybe<(
          { __typename?: 'profile' }
          & Pick<Profile, '_id' | 'username'>
          & { avatar?: Maybe<(
            { __typename?: 'Media' }
            & Pick<Media, 'uri'>
          )> }
        )> }
      )>, comment?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, '_id' | 'content' | 'created'>
        & { profile?: Maybe<(
          { __typename?: 'profile' }
          & Pick<Profile, '_id' | 'username'>
        )>, post: (
          { __typename?: 'Post' }
          & Pick<Post, '_id' | 'created' | 'content' | 'score'>
          & { profile?: Maybe<(
            { __typename?: 'profile' }
            & Pick<Profile, '_id' | 'username'>
          )> }
        ), replyTo?: Maybe<(
          { __typename?: 'Comment' }
          & Pick<Comment, '_id' | 'content' | 'created'>
          & { profile?: Maybe<(
            { __typename?: 'profile' }
            & Pick<Profile, '_id' | 'username'>
          )> }
        )> }
      )>, profile?: Maybe<(
        { __typename?: 'profile' }
        & Pick<Profile, '_id' | 'username'>
        & { avatar?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'uri'>
        )> }
      )> }
    )> }
  ) }
);

export type SearchPostsQueryVariables = Exact<{
  input: SearchPostsInput;
  cursor?: Maybe<CursorInput>;
}>;


export type SearchPostsQuery = (
  { __typename?: 'Query' }
  & { searchPosts: (
    { __typename?: 'SearchPostsResponse' }
    & { cursor?: Maybe<(
      { __typename?: 'CursorObject' }
      & Pick<CursorObject, 'beforeCursor' | 'afterCursor'>
    )>, data: Array<(
      { __typename?: 'Post' }
      & Pick<Post, '_id' | 'score' | 'content' | 'created'>
      & { profile?: Maybe<(
        { __typename?: 'profile' }
        & Pick<Profile, '_id' | 'username'>
        & { avatar?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'uri'>
        )> }
      )>, geocode?: Maybe<(
        { __typename?: 'GeocodeObject' }
        & Pick<GeocodeObject, 'city' | 'region' | 'country'>
      )>, media?: Maybe<Array<(
        { __typename?: 'Media' }
        & Pick<Media, 'uri' | 'width' | 'height' | 'type'>
      )>> }
    )> }
  ) }
);


export const GetReports = gql`
    query GetReports($cursor: CursorInput) {
  getReports(cursor: $cursor) {
    cursor {
      beforeCursor
      afterCursor
    }
    data {
      _id
      created
      reason
      content
      action
      post {
        _id
        score
        content
        created
        profile {
          _id
          username
          avatar {
            uri
          }
        }
        media {
          uri
          width
          height
        }
      }
      story {
        _id
        textContent
        created
        media {
          uri
        }
        profile {
          _id
          username
          avatar {
            uri
          }
        }
      }
      comment {
        _id
        content
        created
        profile {
          _id
          username
        }
        post {
          _id
          created
          profile {
            _id
            username
          }
          content
          score
        }
        replyTo {
          _id
          content
          created
          profile {
            _id
            username
          }
        }
      }
      profile {
        _id
        avatar {
          uri
        }
        username
      }
    }
  }
}
    `;
export const SearchPosts = gql`
    query SearchPosts($input: SearchPostsInput!, $cursor: CursorInput) {
  searchPosts(input: $input, cursor: $cursor) {
    cursor {
      beforeCursor
      afterCursor
    }
    data {
      _id
      score
      content
      created
      profile {
        _id
        username
        avatar {
          uri
        }
      }
      geocode {
        city
        region
        country
      }
      media {
        uri
        width
        height
        type
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetReports(variables?: GetReportsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetReportsQuery> {
      return withWrapper(() => client.request<GetReportsQuery>(GetReports, variables, requestHeaders));
    },
    SearchPosts(variables: SearchPostsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchPostsQuery> {
      return withWrapper(() => client.request<SearchPostsQuery>(SearchPosts, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;