import { IAppState } from "./../interfaces/IAppState";
import {
	ApolloClient, InMemoryCache, HttpLink, ApolloLink,
} from "@apollo/client";
import GlobalState from "../components/state/GlobalState";
import { onError } from "@apollo/client/link/error";

const authMiddleware = new ApolloLink((operation, forward) => {
	const { auth } = GlobalState.getState() as IAppState;
	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			Authorization: `Bearer ${auth.token}`,
		},
	}));
	return forward(operation);
});

const errorLink = onError((error) => {
	if (error.graphQLErrors) {
		error.graphQLErrors.map(async ({ message }) => {
			if (message.includes("invalid_token")) {
				localStorage.clear();
				window.location.reload();
			}
		});
	}
});

const ApolloManager = new ApolloClient({
	cache: new InMemoryCache({
		// typePolicies: {
		// 	Query: {
		// 		fields: {
		// 			getReports: relayStylePagination(),
		// 		},
		// 	},
		// },
	}),
	link: ApolloLink.from([
		authMiddleware,
		errorLink,
		new HttpLink({
			uri: "https://jeti-admin-api-kcka2telhq-uk.a.run.app",
			fetch,
		}),
	]),
});

export default ApolloManager;
