import { combineReducers, createStore } from "redux";
import AuthReducer from "./reducers/AuthReducer";

const appReducer = combineReducers({
	auth: AuthReducer,
});

const storedState = localStorage.getItem("state");
const persistedState = storedState ? JSON.parse(storedState) : {};

const GlobalState = createStore(
	(state, action) => {
		if (action.type === "RESET") {
			state = undefined;
		}
		return appReducer((state as any), action);
	},
	persistedState,
);

GlobalState.subscribe(() => {
	localStorage.setItem("state", JSON.stringify(GlobalState.getState()));
});

export default GlobalState;
