import React, { Component } from "react";
import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import { refreshToken } from "../utils/refreshToken";
import GlobalState from "../components/state/GlobalState";

const CLIENT_ID = "9238770710-ghehs25k4ohjpa8d8cr84j5o7ugnp97i.apps.googleusercontent.com";

class LoginScreen extends Component<any, any> {

	onSuccess = (res: GoogleLoginResponse) => {
		// document.cookie = `g_auth_jeti=${(res as any).accessToken}`;
		refreshToken(res);
		GlobalState.dispatch({
			type: "LOGIN",
			payload: {
				profile: res.profileObj,
				token: res.accessToken,
			},
		});
	};

	onFailure = (res?: any) => {
		console.log(res);
	}

	render() {
		return (
			<div className={"flex h-full items-center justify-center"}>
				<div className={"flex flex-col items-center justify-center"}>
					<GoogleLogin
						clientId={CLIENT_ID}
						buttonText={"Sign in with Google"}
						onSuccess={(res) => {
							if (res.code) {
								return this.onFailure();
							}
							return this.onSuccess(res as any);
						}}
						onFailure={this.onFailure}
						cookiePolicy={"single_host_origin"}
						isSignedIn={false}
					/>
					<span className={"mt-8 text-center text-sm"}>
						Unauthorized access and use of this website is prohibited.
					</span>
				</div>
			</div>
		);
	}

}

export default LoginScreen;
