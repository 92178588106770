import React, { Component } from "react";
import { SearchPostsComponent } from "../api/QueryComponents";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import PostItem from "../components/post/PostItem";
import { Post } from "../api/NetworkManager";

type State = {
	query: string;
	hasMedia: boolean;
}

class SearchScreen extends Component<any, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			query: "",
			hasMedia: false,
		};
	}

	onChangeText = ({ target }: any) => {
		this.setState({
			...this.state,
			query: target.value,
		});
	};

	onChangeToggleMedia = ({ target }: any) => {
		this.setState({
			...this.state,
			hasMedia: target.checked,
		});
	};

	render() {
		const { query, hasMedia } = this.state;
		return (
			<div
				className={"mt-10"}
			>
				<h1 className={"text-4xl"}>
					Search
				</h1>
				<form>
					<input
						type={"text"}
						onChange={this.onChangeText}
						value={query}
					/>
					<Toggle
						id="cheese-status"
						defaultChecked={hasMedia}
						onChange={this.onChangeToggleMedia}
					/>
					<label htmlFor="cheese-status">Adjacent label tag</label>
				</form>
				<div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-5"}>
					<SearchPostsComponent
						variables={{
							input: {
								query,
								hasMedia,
							},
						}}
					>
						{({ data }) => (
							<React.Fragment>
								{data?.searchPosts.data.map((data, i) => (
									<div
										key={i}
										className={"row-span-2"}
									>
										<PostItem post={data as Post}/>
									</div>
								))}
							</React.Fragment>
						)}
					</SearchPostsComponent>
				</div>
			</div>
		);
	}

}

export default SearchScreen;
