import { Comment, Post } from "../api/NetworkManager";
import moment from "moment";

function GetCreatedDate(post?: Post|Comment, dateInput?: Date, withoutSuffix = true) {
	const now = moment();
	let date = moment(post?.created || dateInput);
	if (now.diff(date, "years") >= 1) {
		return date.format("MMM, YYYY");
	} else if (now.diff(date, "week") >= 1) {
		return date.format("MMM Do");
	} else {
		date = moment(post?.created || dateInput);
		// date = moment(post.created, undefined, "abbr");
		return date.fromNow(withoutSuffix);
	}
}

export default GetCreatedDate;
