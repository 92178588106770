import React from "react";
import { ApolloProvider } from "@apollo/client";
import ApolloManager from "./api/ApolloManager";
import { Provider } from "react-redux";
import GlobalState from "./components/state/GlobalState";
import NavigationWrapper from "./navigation/NavigationWrapper";

function App() {
	return (
		<Provider store={GlobalState}>
			<ApolloProvider client={ApolloManager}>
				<NavigationWrapper/>
			</ApolloProvider>
		</Provider>
	);
}

export default App;
