/* eslint-disable */
import * as Operations from './NetworkManager';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Operations.GetReportsQuery, Operations.GetReportsQueryVariables>, 'query'>;

    export const GetReportsComponent = (props: GetReportsComponentProps) => (
      <ApolloReactComponents.Query<Operations.GetReportsQuery, Operations.GetReportsQueryVariables> query={Operations.GetReports} {...props} />
    );
    
export type GetReportsQueryResult = Apollo.QueryResult<Operations.GetReportsQuery, Operations.GetReportsQueryVariables>;
export type SearchPostsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Operations.SearchPostsQuery, Operations.SearchPostsQueryVariables>, 'query'> & ({ variables: Operations.SearchPostsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchPostsComponent = (props: SearchPostsComponentProps) => (
      <ApolloReactComponents.Query<Operations.SearchPostsQuery, Operations.SearchPostsQueryVariables> query={Operations.SearchPosts} {...props} />
    );
    
export type SearchPostsQueryResult = Apollo.QueryResult<Operations.SearchPostsQuery, Operations.SearchPostsQueryVariables>;