import React, { Component } from "react";
import { Report } from "../api/NetworkManager";
import { GetReportsComponent } from "../api/QueryComponents";
import ReportedItem from "../components/reports/ReportedItem";
import * as _ from "lodash";

class ReportScreen extends Component<any, any> {

	private afterCursor?: string|null = null;

	private fetchMore: ((params: any) => any)|null = null;

	componentDidMount() {
		document.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		document.addEventListener("scroll", this.handleScroll);
	}

	handleScroll = (e: any) => {
		const wrappedElement = document.getElementById("reportFeed");
		if (wrappedElement && this.isBottom(wrappedElement)) {
			console.log("[ReportFeed] Fetching more...");
			this.execFetchMore();
		}
	};

	isBottom(el: Element) {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	execFetchMore = _.debounce(() => {
		if (this.fetchMore && this.afterCursor) {
			this.fetchMore({
				variables: {
					input: {
						cursor: {
							afterCursor: this.afterCursor,
						},
					},
				},
				updateQuery: (prev: any, { fetchMoreResult }: any) => {
					if (!fetchMoreResult) {
						return prev;
					}
					this.afterCursor = fetchMoreResult.getReports.cursor.afterCursor;
					return Object.assign({}, prev, {
						getReports: {
							...prev.getReports,
							data: [...prev.getReports.data, ...fetchMoreResult.getReports.data],
						},
					});
				},
			});
		}
	}, 250);

	render() {
		return (
			<div
				className={""}
			>
				<GetReportsComponent
					onCompleted={({ getReports }) => {
						this.afterCursor = getReports.cursor?.afterCursor;
					}}
				>
					{({ data, fetchMore }) => {
						this.fetchMore = fetchMore;
						return (
							<div
								id={"reportFeed"}
								className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-5"}
							>
								{data ? data.getReports.data.map((report, i) => (
									<ReportedItem
										key={i}
										report={report as Report}
									/>
								)) : null}
							</div>
						);
					}}
				</GetReportsComponent>
			</div>
		);
	}

}

export default ReportScreen;
